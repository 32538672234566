import { useAuth } from "@behaviour-lab/blab-component-library/auth"
import useSWR, { useSWRConfig } from "swr"

import { SettingsResponseType } from "src/types"

import { getFetcher, ApiUrl } from "./fetcher/instances"
import { fetcherOptions } from "./fetcher/options"

export const usePortfolioSettingsSWR = (orgId: number, portfolioId: number) => {
  const { logout } = useAuth()

  return useSWR(
    `/analytics/settings/${orgId}/${portfolioId}`,
    url =>
      getFetcher(ApiUrl.NEXT_PUBLIC_ANALYTICS_API, {
        url,
        tokenExpiredCb: logout,
      }),
    fetcherOptions,
  )
}

export const useRevalidatePortfolioSettingsSWR = (
  orgId: number,
  portfolioId: number,
) => {
  const { mutate } = useSWRConfig()

  return {
    triggerRevalidatePortfolioSettings: (
      updatedParameters: SettingsResponseType,
    ) => {
      mutate(
        `/analytics/settings/${orgId}/${portfolioId}`,
        () => updatedParameters,
        false,
      ) // false here means to not revalidate data with the remote API
    },
    triggerCleanUpParameters: () =>
      mutate(`/analytics/settings/${orgId}/${portfolioId}`, undefined, false), // false here means to not revalidate data with the remote API
  }
}
