import { useAuth } from "@behaviour-lab/blab-component-library/auth"
import useSWR from "swr"

import { getDataLake } from "src/lib/common"
import { PluginNameEnum } from "src/types"

import { postMutator, ApiUrl } from "./fetcher/instances"
import { fetcherOptions } from "./fetcher/options"

interface IProps {
  orgId: number
  portfolioId: number
  benchmarkSedol: string
}

export function usePortfolioConstituentLookupTableSWR({
  orgId,
  portfolioId,
  benchmarkSedol,
}: IProps) {
  const { logout } = useAuth()
  const url = "/analytics/coordinator"

  return useSWR(
    orgId && portfolioId && benchmarkSedol
      ? // Here we need a unique cache key with orgId and portfolioId to have the correct data in cache per orgId and portfolioId
        `/${orgId}/${portfolioId}/${PluginNameEnum.PORTFOLIO_CONSTITUENT_LOOKUP_TABLE}`
      : null,
    () =>
      postMutator(ApiUrl.NEXT_PUBLIC_ANALYTICS_API, {
        url,
        tokenExpiredCb: logout,
        options: {
          arg: {
            bucket: getDataLake(),
            client: `${orgId}`,
            portfolio: `${portfolioId}`,
            plugin: PluginNameEnum.PORTFOLIO_CONSTITUENT_LOOKUP_TABLE,
            parameters: { benchmark_sedol: benchmarkSedol },
          },
        },
      }),
    fetcherOptions,
  )
}
